import internalServerErrorAction, {IWSError,} from "../../state/actions/internalServerError";
import loadingUnsetAction from "../../state/actions/loadingUnset";
import {actionIds} from "../../state/common";
import setConfigAction from "../../state/actions/setConfig";
import errorAccessDeniedAction from "../../state/actions/errorAccessDenied";
import setTestresultsAction from "../../state/actions/setTestresults";

const handleMessages = (dispatch) => (event) => {
  dispatch(loadingUnsetAction());
  const data = JSON.parse(event.data);

  switch (data.type) {
    case actionIds.SET_CONFIG:
      dispatch(setConfigAction(data.payload));
      break;
    case actionIds.SET_TESTRESULTS:
      dispatch(setTestresultsAction(data.payload));
      break;
    case actionIds.ERROR_INTERNAL_SERVER_ERROR:
      dispatch(internalServerErrorAction(data.payload as IWSError));
      break;
    case actionIds.ERROR_ACCESS_DENIED:
      dispatch(errorAccessDeniedAction());
      break;

    default:
      console.error("websocket message unknown", data);
  }
};

export default handleMessages;
