import {actionIds, BaseAction} from "../common";
import {IConfig} from "../../types/IConfig";

const setConfigAction: (config: IConfig) =>
    BaseAction = (config) => ({
    type: actionIds.SET_CONFIG,
    payload: {
        ...config,
    },
});

export default setConfigAction;
