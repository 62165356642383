import {combineReducers} from "redux";
import {IRootState} from "../../types/IRootState";
import configReducer from "./configReducer";
import connectionReducer from "./connectionReducer";
import testresultsReducer from "./testresultsReducer";

const rootReducer = combineReducers<IRootState>({
  config: configReducer,
  connection: connectionReducer,
  testresults: testresultsReducer,
});

export default rootReducer;
