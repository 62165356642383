import React from "react";
import {Navigate, Route, Routes, unstable_HistoryRouter as HistoryRouter,} from "react-router-dom";
import {StatusPage} from "./components/pages/StatusPage";
import routes from "./config/routes";

interface Props {
  history: any;
}

const RouterWrapper = (props: Props) => {
  const { history } = props;

  return (
    <HistoryRouter history={history}>
      <div>
            <Routes>
              <>
                <Route
                  path={routes.HOME_PAGE}
                  element={
                      <StatusPage />
                  }
                />

                <Route
                  path="*"
                  element={<Navigate to={routes.HOME_PAGE} replace />}
                />
              </>
            </Routes>
      </div>
    </HistoryRouter>
  );
};

export default RouterWrapper;
