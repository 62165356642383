import './StatusPage.scss'
import {useDispatch, useSelector} from "react-redux";
import {IRootState} from "../../types/IRootState";
import classNames from "classnames";
import {useEffect} from "react";
import getTestresultsAction from "../../state/actions/getTestresults";

export const StatusPage = () => {
    const isConnected = useSelector((state: IRootState) => state.connection.isConnected)
    const testresults = useSelector((state: IRootState) => state.testresults)

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getTestresultsAction())
    }, [dispatch])

    return (<div className="status-page">
        <h1>S-KON goes Europe - Agent</h1>
        <div>&nbsp;</div>
        <div className="status-grid">
            <div>WebSocket connection to backend:</div>
            <div><div className={classNames('status-light', isConnected ? 'status-light--green' : 'status-light--red')}></div></div>
            <div>Backend: Database connection:</div>
            <div><div className={classNames('status-light', testresults.db_connection ? 'status-light--green' : testresults.isInitialized ? 'status-light--red' : '')}></div></div>
            <div>Backend: LDAP connection:</div>
            <div><div className={classNames('status-light', testresults.ldap_connection ? 'status-light--green' : testresults.isInitialized ? 'status-light--red' : '')}></div></div>
            <div>Backend: Mail connection:</div>
            <div><div className={classNames('status-light', testresults.mail_connection ? 'status-light--green' : testresults.isInitialized ? 'status-light--red' : '')}></div></div>
            <div>Backend: SMS connection:</div>
            <div><div className={classNames('status-light', testresults.sms_connection ? 'status-light--green' : testresults.isInitialized ? 'status-light--red' : '')}></div></div>
        </div>
    </div>)
}