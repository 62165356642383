import {all, call, ForkEffect, put, spawn, takeLatest,} from "redux-saga/effects";
import {actionIds, BaseAction} from "../common";
import {IWebSocket} from "../../api/sockets";
import {IConfig} from "../../types/IConfig";
import setConfig from "../actions/setConfig";

type TSaga = (params?: any) => Generator<ForkEffect<never>, void, unknown>;

interface IRootSagaParams {
  history: any;
  socket: IWebSocket;
}

const rootSaga = function* root(params: IRootSagaParams) {
  const sagas: TSaga[] = [
    watchSetConfigRequest,
    watchGetTestresultsRequest,
  ];

  yield all(
    sagas.map((saga) =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga, params);
            break;
          } catch (e) {
            console.error(e);
          }
        }
      })
    )
  );
};

function* watchSetConfigRequest(params) {
  yield takeLatest(
    actionIds.SET_CONFIG,
    function* (action: BaseAction<IConfig>) {
      yield put(setConfig(action.payload))
    }
  );
}

function* watchGetTestresultsRequest(params) {
  yield takeLatest(
    actionIds.GET_TESTRESULTS,
    function* (action: BaseAction) {
      yield params.socket.safeSend(JSON.stringify(action));
    }
  );
}

export default rootSaga;
