import {applyMiddleware, createStore} from "redux";
import {composeWithDevTools} from "redux-devtools-extension";
import RouterWrapper from "./RouterWrapper";
import {Provider} from "react-redux";
import createSagaMiddleware from "redux-saga";
import {createBrowserHistory} from "history";
import rootReducer from "./state/reducers";
import setupSocket, {IWebSocket} from "./api/sockets";
import rootSaga from "./state/sagas";


// Browser History for use with react router
const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();
const store = createStore(
    rootReducer,
    undefined,
    composeWithDevTools(applyMiddleware(sagaMiddleware))
);

const socket: IWebSocket = setupSocket(store.dispatch);
sagaMiddleware.run(rootSaga, { socket, history });

const App = () => {
  return (
      <Provider store={store}>
        <RouterWrapper history={history} />
      </Provider>
  );
};

export default App;
