export const actionIds = {
    RESET_STORE: 'RESET_STORE',
    ERROR_ACCESS_DENIED: 'ERROR_ACCESS_DENIED',
    ERROR_INTERNAL_SERVER_ERROR: 'ERROR_INTERNAL_SERVER_ERROR',
    LOADING_SET: 'LOADING_SET',
    LOADING_UNSET: 'LOADING_UNSET',
    GET_CONFIG: 'GET_CONFIG',
    SET_CONFIG: 'SET_CONFIG',
    CONNECTED: 'CONNECTED',
    RECONNECT: 'RECONNECT',
    RECONNECT_ABORT: 'RECONNECT_ABORT',
    DISCONNECTED: 'DISCONNECTED',
    GET_TESTRESULTS: 'GET_TESTRESULTS',
    SET_TESTRESULTS: 'SET_TESTRESULTS',
};

export interface BaseAction<T = any> {
    type: string;
    payload: T;
}
