import handleMessages from './handleMessages';
import { WebSocketConfig } from '../../config/websocket';
import connectedAction from "../../state/actions/connected";
import disconnectedAction from "../../state/actions/disconnected";
import reconnectAction from "../../state/actions/reconnect";
import reconnectAbortAction from "../../state/actions/reconnectAbort";

const MAX_RECONNECT_ATTEMPTS = 60

interface IWebSocketSafeSend {
  safeSend?: (request: any) => void
}

export interface IWebSocket extends WebSocket, IWebSocketSafeSend {}

const setupSocket = (dispatch) => {
  let socket: IWebSocket;
  let reconnectionAttempts = 0
  let sendQueue: any[] = []
  let isConnected = false

  const connect = () => {
    const websocketUrl =
      document.location.protocol.indexOf('https') !== -1 ? WebSocketConfig.urlTls : WebSocketConfig.url
    socket = new WebSocket(websocketUrl.replace('{domain}', document.location.hostname))

    socket.onopen = () => {
      if(sendQueue.length > 0) {
        sendQueue = sendQueue.filter(request => {
          socket.send(request);
          return false
        })
      }
      dispatch(connectedAction())
      isConnected = true;
      reconnectionAttempts = 0
    }

    socket.onmessage = handleMessages(dispatch)

    socket.onclose = (e) => {
      if (reconnectionAttempts < MAX_RECONNECT_ATTEMPTS) {
        console.log('Socket is closed. Reconnect will be attempted in 1 second.', e.reason)
        dispatch(disconnectedAction())
        isConnected = false;
        dispatch(reconnectAction())
        reconnectionAttempts++
        setTimeout(function() {
          connect();
        }, 1000);
      } else {
        console.log('Reconnection failed after ' + reconnectionAttempts + ' attempts, aborting')
        dispatch(reconnectAbortAction())
      }
    }

    socket.onerror = () => {
      // TODO: Add error handling
      socket.close();
    }

    socket.safeSend = (request) => {
      if (isConnected) {
        socket.send(request)
      } else {
        sendQueue.push(request)
      }
    }

    return socket
  }

  return connect()
}

export default setupSocket
