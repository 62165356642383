import { actionIds, BaseAction } from "../common";

export interface IWSError {
  key: string;
  message: string;
  details?: string;
}

const internalServerErrorAction: (payload: IWSError) => BaseAction = (
  payload
) => ({
  type: actionIds.ERROR_INTERNAL_SERVER_ERROR,
  payload,
});

export default internalServerErrorAction;
