import {actionIds, BaseAction} from "../common";
import {ITestresults} from "../../types/ITestresults";

const setTestresultsAction: (testresults: ITestresults) =>
    BaseAction = (testresults) => ({
    type: actionIds.SET_TESTRESULTS,
    payload: {
        ...testresults,
    },
});

export default setTestresultsAction;
