import {actionIds, BaseAction} from "../common";
import {IConnectionState} from "../../types/IConnectionState";

const defaultState: IConnectionState = {
    isInitialized: false,
    isConnected: false,
    isReconnecting: false,
    disconnectedAt: new Date()
}

const connectionReducer = (state: IConnectionState = defaultState, action: BaseAction): IConnectionState => {
    switch (action.type) {
        case actionIds.CONNECTED:
            return {
                ...state,
                isInitialized: true,
                isConnected: true,
                isReconnecting: false,
            }

        case actionIds.DISCONNECTED:
            return {
                ...state,
                isInitialized: true,
                isConnected: false,
                disconnectedAt: new Date()
            }

        case actionIds.RECONNECT:
            return {
                ...state,
                isInitialized: true,
                isReconnecting: true
            }

        case actionIds.RECONNECT_ABORT:
            return {
                ...state,
                isInitialized: true,
                isReconnecting: false
            }

        default:
            return state
    }
}

export default connectionReducer;
